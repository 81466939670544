<template>
  <div>
    <CCard>
      <CCardHeader>Create New Site</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md='12' lg='6'>
            <CForm @submit='submitForm'>
              <CInput required placeholder='Site Name' v-model='nameInput.val' v-bind:class="{ valid: validateInput(nameInput.val, 'name') }" :invalidFeedback="invalidFeedback('name')" :isValid="validateInput(nameInput.val, 'name')">
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <CInput required placeholder='Domain' v-model='domainInput.val' v-bind:class="{ valid: validateInput(domainInput.val, 'domain') }" :invalidFeedback="invalidFeedback('domain')" :isValid="validateInput(domainInput.val, 'domain')">
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <CInput required placeholder='Site Code' v-model='codeInput.val' v-bind:class="{ valid: validateInput(codeInput.val, 'code') }" :invalidFeedback="invalidFeedback('code')" :isValid="validateInput(codeInput.val, 'code')">
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <div class='form-group form-actions'>
                <CButton block type='submit' color='success' :disabled='formLoading'>
                  <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
                  Create
                </CButton>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import apiSite from '@/api/site'

export default {
  name: 'CreateSite',
  data () {
    return {
      formLoading: false,
      nameInput: { val: null, serverErrMsg: null , serverErrVal: null },
      domainInput: { val: null, serverErrMsg: null , serverErrVal: null },
      codeInput: { val: null, serverErrMsg: null , serverErrVal: null }
    }
  },
  methods: {
    validateInput (val, field) {
      if(val === null){
      }else{
        if(field == 'name'){ // alphanumeric with whitespace
          return (val && /^[a-zA-Z0-9\s]+$/.test(val) && val !== this.nameInput.serverErrVal) ? true : false
        }
        if(field == 'domain'){ // domain match
          return (val && /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(val) && val !== this.domainInput.serverErrVal) ? true : false
        }
        if(field == 'code'){ // 2 alphanumeric chars
          return (val && val.length == 2 && /^[A-Z0-9]+$/.test(val) && val !== this.codeInput.serverErrVal) ? true : false
        }
      }
    },
    invalidFeedback (field) {
      if(field == 'name'){
        return (this.nameInput.val !== this.nameInput.serverErrVal) ? 'Alphanumeric characters and spaces only' : this.nameInput.serverErrMsg
      }
      if(field == 'domain'){
        return (this.domainInput.val !== this.domainInput.serverErrVal) ? 'Invalid domain name' : this.domainInput.serverErrMsg
      }
      if(field == 'code'){
        return (this.codeInput.val !== this.codeInput.serverErrVal) ? '2 alphanumeric characters only' : this.codeInput.serverErrMsg
      }
    },
    submitForm: function (e) {

      e.preventDefault()

      if(this.validateInput(this.nameInput.val, 'name') && this.validateInput(this.domainInput.val, 'domain') && this.validateInput(this.codeInput.val, 'code')){
        
        this.formLoading = true

        apiSite.create(this.nameInput.val, this.domainInput.val, this.codeInput.val).then(response => {
          this.formLoading = false
          this.$toast.success('Successfully created new site.', { position: 'top-right', timeout: 3000, hideProgressBar: true })
          this.$router.go(-1) 
        }).catch((err) => {
          this.formLoading = false
          if(err.response.data.status == 'validation.error' && err.response.data.errors){
            err.response.data.errors.forEach((error) => {
              if(error.field == 'name'){
                this.nameInput.serverErrVal = this.nameInput.val
                this.nameInput.serverErrMsg = error.message
              }
              if(error.field == 'domain'){
                this.domainInput.serverErrVal = this.domainInput.val
                this.domainInput.serverErrMsg = error.message
              }
              if(error.field == 'code'){
                this.codeInput.serverErrVal = this.codeInput.val
                this.codeInput.serverErrMsg = error.message
              }
            });
          }
          this.$toast.error('Failed to create new site.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        })

      }

    }
  }
}
</script>
